import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ActivityList from './activity_list.vue';
import PersonList from './person_list.vue';

formCreate.component('ActivityList', ActivityList);
formCreate.component('PersonList', PersonList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formRole: null,
      rule: [],
      formFunctionCode: 'inspection_center_form',
      formParentCode: 'CRM20210928000002730',
    };
  },
  created() {
    if (this.formConfig && this.formConfig.row && this.formConfig.row.formRole) {
      this.formRole = this.formConfig.row.formRole;
    }
  },
  methods: {
    // 签批之前提交方法
    async beforeSubmit(code) {
      if (code === 'BTN_004') { // 审批通过
        if (this.formRole !== 'view3') {
          return true;
        }
        const params = this.getFormData();
        if (params) {
          const url = '/tpm/tpmSupervise/auditUpdate';
          params.id = this.formConfig.row.id;
          const res = await request.post(url, params);
          if (res.success) {
            return true;
          }
          return false;
        }
        return false;
      }
      return true;
    },

    setRule(item) {
      const v = item;
      if (v.field === 'actDetailName') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'activity_list',
            paramData: {
              isSuperviseQuery: 'Y',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'superviseUserName') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'person_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      return item;
    },

    formComplete() {
      console.log(this.formConfig);
      const { type } = this.formConfig;
      // 活动明细
      const actDetailName = this.getRule('actDetailName');
      actDetailName.on.change = (e) => {
        if (e) {
          this.setValue({
            actDetailCode: e[0].actDetailCode,
            actDetailName: e[0].ext1,
            actCode: e[0].actCode,
            actName: e[0].actName,
          });
        } else {
          this.setValue({
            actDetailCode: null,
            actDetailName: null,
            actCode: null,
            actName: null,
          });
        }
      };

      // 督查人
      const superviseUserName = this.getRule('superviseUserName');
      superviseUserName.on.change = (e) => {
        if (e) {
          this.setValue({
            superviseUserCode: e[0].userName,
            superviseUserName: e[0].fullName,
          });
        } else {
          this.setValue({
            superviseUserCode: null,
            superviseUserName: null,
          });
        }
      };

      if (type === 'edit' || type === 'view' || this.formConfig.code === 'view') {
        request.get('/tpm/tpmSupervise/query', { id: this.formConfig.id ?? this.formConfig.row.id }).then((res) => {
          if (res.success) {
            const { result } = res;
            setTimeout(() => {
              this.setValue({
                ...result,
                attachmentVoList: result.attachmentVoList || [],
              });
              // 角色为view3的时候可以编辑
              if (this.formRole === 'view3') {
                const complianceStatus = this.getRule('complianceStatus');
                complianceStatus.props.disabled = false;
              }
            }, 100);
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmSupervise/add';
        // 有id  就是编辑
        if (this.formConfig.id) {
          formData.id = this.formConfig.id;
          url = '/tpm/tpmSupervise/update';
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
